
export const DECIMAL_THOUSANDS_SEPARATOR_OPTS = {
  dotcomma: 'dotcomma', // Decimal dot and comma as thousands separator
  commadot: 'commadot' // Decimal comma and dot as thousands separator
}

export const INVALID_NUMBER_REPR = '-'

/** Applies the formatting used in unNumberFmt filter, but this functions expect a valid amount **/
function formatValidNumber (amount, decimalCountValue, separatorOptions) {
  const decimalCount = decimalCountValue
  const decimal = separatorOptions === DECIMAL_THOUSANDS_SEPARATOR_OPTS.dotcomma ? '.' : ','
  const thousands = separatorOptions === DECIMAL_THOUSANDS_SEPARATOR_OPTS.dotcomma ? ',' : '.'

  const negativeSign = amount < 0 ? '-' : ''

  let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
  let j = (i.length > 3) ? i.length % 3 : 0

  return negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
}

/**
 * Returns the passed value formatted
 * @param {number} amount The value.
 * @param {number} decimalCountValue The number of decimals, must be a natural number, default: 2
 * @param {string} separatorOptions 'commadot' or 'dotcomma'. Decimal dot and comma thousand separator, default: 'commadot, ie. 1.234,56
 * @return {string} A string with the formatted number , else '0.0'
 */
export const unNumberFmt = function (
  amount,
  decimalCountValue = 2,
  separatorOptions = DECIMAL_THOUSANDS_SEPARATOR_OPTS.commadot
) {
  let formatted
  if (amount !== null && amount !== undefined && amount !== '' && !isNaN(amount)) {
    try {
      formatted = formatValidNumber(amount, decimalCountValue, separatorOptions)
    } catch (e) {
      return INVALID_NUMBER_REPR
    }
    return formatted
  }
  return INVALID_NUMBER_REPR
}

/**
 * Take a value and if it is a number , will return the number formatted with no decimals but thousands separator
 * @param {number} value to format.
 * @return {string} A string with the formatted number. Returns '-' for invalid numbers.
 */
export const unIntegerFmt = function (value) {
  // the thousand separator wasn't supported for locales 'es-ES', that's why it was changed to 'de-DE' which does
  return (value !== null && value !== undefined && value !== '' && !isNaN(value)) ? Number(value).toLocaleString(
    'de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : INVALID_NUMBER_REPR
}

/**
 * Take a value and if it is a number , will return the number as percentage (multiplied by 100)
 * with the passed decimals and thousands separator
 * @param {number} value to format. Will be multiplied by 100 in the output percentage
 * @param {number} decimalCountValue The number of decimals, must be a natural number, default: 2
 * @param {string} separatorOptions 'commadot' or 'dotcomma'. Decimal dot and comma thousand separator, default: 'commadot, ie. 1.234,56
 * @return {string} A string with the formatted number Ex for 12.12 will return 12'12 %. Returns '-' for invalid numbers
 */
export const unPercentFmt = function (
  value,
  decimalCountValue,
  separatorOptions = DECIMAL_THOUSANDS_SEPARATOR_OPTS.commadot
) {
  if (value !== null && value !== undefined && value !== '' && !isNaN(value)) {
    return `${unNumberFmt(Number(value) * 100, decimalCountValue, separatorOptions)}%`
  } else {
    return '-'
  }
}

/**
 * Take a value and if it is a number , will return the number as percentage (multiplied by 100)
 * with the passed decimals and thousands separator
 * @param {string} value to format
 * @param {string} separatorOptions 'commadot' or 'dotcomma'. Decimal dot and comma thousand separator, default: 'commadot, ie. 1.234,56
 * @return {number} A number with the parsed value. Returns NaN for invalid numbers
 */
export const unNumberParse = function (
  value,
  separatorOptions = DECIMAL_THOUSANDS_SEPARATOR_OPTS.commadot
) {
  if (value === null || value === undefined || value === '') {
    return NaN
  }
  let aux = String(value)
  // parseFloat expects a number with no thousand separator and dot as decimal separator
  if (separatorOptions === DECIMAL_THOUSANDS_SEPARATOR_OPTS.commadot) { // ES numbers
    aux = aux.replace(/\./g, '').replace(',', '.')
  } else if (separatorOptions === DECIMAL_THOUSANDS_SEPARATOR_OPTS.dotcomma) { // US numbers
    aux = aux.replace(/,/g, '')
  } else {
    aux = value
  }
  return parseFloat(aux)
}

export const filtersMixin = {
  /* filter for typical currencies */
  filters: {
    unNumberFmt,
    unPercentFmt,
    unIntegerFmt
  }
}
